<template>
  <div
    v-if="!$wait.is('downloads.get')"
    class="d-flex justify-center full-width py-15"
  >
    <div class="d-flex flex-column">
      <h1 class="text-center mb-5">Downloads</h1>
      <div>
        <v-text-field
          v-model="downloadSearch"
          class="mb-5"
          clearable
          dense
          filled
          flat
          hide-details
          placeholder="Type here to search"
          prepend-inner-icon="mdi-magnify"
          @click:clear="downloadSearch = ''"
        />
      </div>

      <div class="flex-1 overflow-y-auto overflow-x-hidden">
        <div
          v-for="download in filteredDownloads"
          :key="download.id"
          class="d-flex ant-glass-background mt-5 px-5"
          style="width: 600px"
        >
          <div class="py-5">
            <v-icon large>{{ getIconByMimetype(download.mimetype) }}</v-icon>
          </div>

          <v-divider class="mx-5" vertical />

          <div class="py-2 d-flex flex-column">
            <span
              class="text-decoration-underline text-primary c-pointer"
              @click="downloadFile(download)"
              >{{ download.name }}. {{ download.extension }}</span
            >
            <v-spacer />
            <span class="fs-10 font-italic">{{ download.created_at }}</span>
          </div>
          <v-spacer />
          <div class="d-flex align-center justify-center">
            <v-btn v-if="!download.downloaded" icon>
              <v-icon class="ant-icon" @click="downloadFile(download)"
                >mdi-download
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="d-flex align-center justify-center full-width full-height">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from '@/components/AntLoading.vue';
import { mapGetters } from 'vuex';
import LocalStorageService from '@/services/local-storage';
import appConfig from '@/appConfig';

export default {
  name: 'Downloads',
  components: { AntLoading },
  data: () => {
    return {
      downloadSearch: '',
    };
  },
  computed: {
    ...mapGetters(['userDownloads']),

    filteredDownloads() {
      if (this.downloadSearch.length >= 2) {
        return this.userDownloads.filter((download) =>
          download.name.includes(this.downloadSearch)
        );
      }

      return this.userDownloads;
    },
  },
  mounted() {
    this.$store.dispatch('fetchUserDownloads');
  },
  methods: {
    getIconByMimetype(mimetype) {
      if (mimetype.includes('image')) {
        return 'mdi-file-image';
      } else if (mimetype.includes('pdf')) {
        return 'mdi-file-pdf-box';
      } else if (mimetype.includes('csv')) {
        return 'mdi-file-table';
      } else if (mimetype.includes('zip')) {
        return 'mdi-folder-zip';
      } else {
        return 'mdi-file-question';
      }
    },
    async downloadFile(download) {
      let url =
        appConfig.VUE_APP_BASE_URL +
        appConfig.VUE_APP_API_URL +
        `/downloads/${download.id}`;

      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + LocalStorageService.getToken(),
        },
      }).then((response) => {
        const filename = `${download.name}.${download.extension}`;
        return response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            download.downloaded = true;
          }, 0);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
